// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';
import { useDigitalShowroomContext } from 'providers/app-provider/context';
import { useEffect } from 'react';

datadogRum.init({
  applicationId: process.env.DATA_DOG_APPLICATION_ID,
  clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'digital-rooms',
  env: process.env.NODE_ENV,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'allow'
});

interface DataDogProviderProps {
  children: React.ReactNode;
}

export const DataDogProvider = ({ children }: DataDogProviderProps) => {
  const { loggedInUserEmail, covetrusUser } = useDigitalShowroomContext();

  useEffect(() => {
    if (loggedInUserEmail || covetrusUser) {
      datadogRum.setUser({
        email: loggedInUserEmail || covetrusUser?.contact?.email,
        name: covetrusUser?.contact?.name
      });
    }
  }, [covetrusUser, loggedInUserEmail]);

  return <>{children}</>;
};
