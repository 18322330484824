import Box from '@mui/material/Box';
import { SEmptyState } from '@setvi/shared/components';
import { useQueryParam } from 'use-query-params';
import { useVetsuiteDigitalShowroomContext } from '../../providers/context';
import { RoomLayout, Header } from '../../../../components';
import { Content } from '../content';

export const DigitalShowroom = () => {
  const { data } = useVetsuiteDigitalShowroomContext();
  const [isIpad] = useQueryParam('isIpad');

  return (
    <RoomLayout
      id="ipad-scroll-container"
      sx={{
        backgroundImage:
          'linear-gradient(#033f73 1px, transparent 1px), linear-gradient(to right, #033f73 1px, transparent 1px)',
        backgroundSize: '100px 100px',
        backgroundColor: '#01294b',
        minHeight: '100%',
        height: isIpad ? 'fit-content' : '100%'
      }}>
      {data ? (
        <Box
          maxHeight={!isIpad && '98%'}
          height={!isIpad && '100%'}
          maxWidth="1600px"
          width="80vw"
          display="flex"
          flexDirection="column"
          sx={theme => ({
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
              width: '100%',
              maxHeight: '100%'
            }
          })}>
          <Header
            companyLogoSrc="/images/vetsuite-teal-inverse.png"
            representatioveFullName={data?.managerName}
            representativeEmail={data?.managerEmail}
            // representativeImageSrc="/images/covetrus-logo.png"
          />
          <Content overview={data} />
        </Box>
      ) : (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="15px"
            width="450px"
            p={4}
            sx={theme => ({ backgroundColor: theme.palette.common.white })}>
            <SEmptyState
              imageSrc="/images/maintenance.svg"
              subTitle={`We're sorry, Covetrus VetSuite Dashboard is temporarily down for maintenance right now. We will be back shortly. Thank you for your patience.`}
            />
          </Box>
        </Box>
      )}
    </RoomLayout>
  );
};
