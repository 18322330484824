import ReactQueryProvider from '@setvi/shared/providers/react-query';
import { Outlet } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { ThemeProvider } from '@mui/material';
import { AppProvider } from 'providers/app-provider/provider';
import { muiTheme } from '@setvi/shared/styles';
import CustomSnackbarProvider from '@setvi/shared/providers/custom-snackbar';
import DialogProvider from '@setvi/shared/providers/dialog';
import { DataDogProvider } from 'providers/datadog-provider';
import './App.css';

const App = () => (
  <ThemeProvider theme={muiTheme}>
    <CustomSnackbarProvider>
      <ReactQueryProvider>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <DialogProvider>
            <AppProvider>
              <DataDogProvider>
                <Outlet />
              </DataDogProvider>
            </AppProvider>
          </DialogProvider>
        </QueryParamProvider>
      </ReactQueryProvider>
    </CustomSnackbarProvider>
  </ThemeProvider>
);

export default App;
